/*!
 *  Bigs 0.1.0
 *  Copyright (C) 2018 pbwebdev
 *  Licensed under GPL-2.0.
 */

(function() {
	'use strict';

	//$('#chook-modal').modal('show');

}());
